















































































@use "@material/data-table/data-table";
@include data-table.core-styles;

.trash-icon:hover{
  cursor: pointer;
}

.history-not-correct{
  background-color: rgb(233, 188, 188) !important;
}


.history-correct{
  background-color: rgb(188, 233, 190) !important;
}

.mdc-data-table__header-cell,
.mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.6);
  background-color: #F3F4F7;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;}

.mdc-data-table {
  width: 100%;}

.mdc-data-table__row,
.mdc-data-table__cell {
  background-color: white;
  color: black;}

.inteligence-artificial .mdc-data-table__row .mdc-data-table__cell {
  color: black;
  font-size: 10px;
  width: 100%;
}

.inteligence-artificial .mdc-data-table__header-cell {
  font-size: 10px;
}

.mdc-data-table__table-container {
  overflow-x: auto; 
}
#best-bullish {
  .mdc-data-table__cell {
    background-color: white;
    color: black;}
  .mdc-data-table__table-container {
    height: 210px;
    background: white;
  }
}

#last-ipos {
  .mdc-data-table__cell {
    background-color: white;
    color: black;
  }
  .mdc-data-table__table-container {
    height: 260px;
    background: white;
  }
}

#hot-values {
  .mdc-data-table__table-container {
    height: 210px;
    background: white;
  }
}

#waiting-values {
  .mdc-data-table__table-container {
    max-height: 210px;
    background: white;
  }
}

